import React, { createContext, useContext, useEffect, useState } from 'react';
import { getFleetDriverList, getManualDispatchProgramShuttles } from '../../../../services/dispatch';

const AppContext = createContext(undefined);

export const useDispatchBoardContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

export const DispatchBoardProvider = ({ children }) => {
  const [programLists, setProgramLists] = useState([]);

  const [selectedDateForDisplay, setSelectedDateForDisplay] = useState(new Date());
  const [bookingsForAutoSchedule, setBookingsForAutoSchedule] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedProgramIds, setSelectedProgramIds] = useState([]);
  const [heatMap, setHeatMap] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('');
  const [paymentType, setPaymentType] = useState([]);
  const [showHeatMap, setShowHeatMap] = useState(false);
  const [refreshed, setRefreshed] = useState(new Date().getTime());
  const [bookingList, setBookingList] = useState([]);
  const [tripsFixedHeight, setTripFixedHeight] = useState(false);
  const [isShowHeatMapMap, setIsShowHeatMapMap] = useState(false);
  const [tripList, setTripList] = useState([]);
  const [vehicleTripList, setVehicleTripList] = useState([]);
  const [addedVehicle, setAddedVehicle] = useState([]);
  const [assignedDriver, setAssignedDriver] = useState([]);
  const [updateThisVehicle, setUpdateThisVehicle] = useState({
    vehicleDetails: null,
    columnNumber: '',
    isUpdateVehicle: false,
    isAutoSchedule: false,
  });
  const [tripRecommendationData, setTripRecommendationData] = useState({
    newTrip: {},
    sortedRecommendedTrip: [],
    vehicleIndex: -1,
  });
  const [driverLists, setDriverLists] = useState([]);
  const [autoScheduleData, setAutoScheduleData] = useState([]);
  const [duplicateDriverAndVehicle, setDuplicateDriverAndVehicle] = useState(false);

  const sortVehicles = (vehicles) => {
    const vehicleList = vehicles.sort(compareVehicle);
    return vehicleList;
  };

  const compareVehicle = (a, b) => {
    const genreA = a.shuttle_name;
    const genreB = b.shuttle_name;

    let comparison = 0;
    if (genreA < genreB) {
      comparison = -1;
    } else if (genreA > genreB) {
      comparison = 1;
    }
    return comparison;
  };

  const compareDriver = (a, b) => {
    const genreA = a.fullname;
    const genreB = b.fullname;

    let comparison = 0;
    if (genreA < genreB) {
      comparison = -1;
    } else if (genreA > genreB) {
      comparison = 1;
    }
    return comparison;
  };

  const getDriverLists = async () => {
    try {
      if (driverLists.length === 0) {
        const resp = await getFleetDriverList();

        if (resp.status === 'success') {
          let list = resp.data.map((driver) => ({
            ...driver,
            fullname: `${driver.firstname} ${driver.lastname}`,
          }));
          list = list.sort(compareDriver);

          setDriverLists(list);

          return list;
        }
      }

      return driverLists;
    } catch (e) {
      console.error(e);
    }

    return [];
  };

  const handleUpdateVehicle = (vehicleDetails, isUpdateVehicle, columnNumber, isAutoSchedule = false) => {
    setUpdateThisVehicle({
      vehicleDetails,
      columnNumber,
      isUpdateVehicle,
      isAutoSchedule,
    });
  };

  const getVehicleList = async (getFresh = false) => {
    try {
      if (getFresh || vehicleTripList.length === 0) {
        const param = { programs: selectedProgramIds };
        const resp = await getManualDispatchProgramShuttles(param);
        let vehicleListsBuf = [];

        if (resp.status === 'success') {
          resp.data.shuttles.forEach((element) => {
            vehicleListsBuf.push({ ...element, isAssigned: false });
          });

          vehicleListsBuf = sortVehicles(vehicleListsBuf);
          setVehicleTripList(vehicleListsBuf);
          return [...vehicleListsBuf];
        }
      } else {
        return vehicleTripList;
      }
    } catch (e) {
      console.error(e);
    }
    return [];
  };

  useEffect(() => {
    if (selectedDateForDisplay) {
      const d = selectedDateForDisplay.getDate();
      const m = selectedDateForDisplay.getMonth() + 1;
      const month = m < 10 ? `0${m}` : m;
      const day = d < 10 ? `0${d}` : d;

      setSelectedDate(`${selectedDateForDisplay.getFullYear()}-${month}-${day}`);
    }
  }, [selectedDateForDisplay]);

  useEffect(() => {
    setAddedVehicle([...tripList]);
  }, [tripList]);

  useEffect(() => {
    if (refreshed) {
      setAutoScheduleData([]);
    }
  }, [refreshed]);

  return (
    <AppContext.Provider
      value={{
        assignedDriver,
        setAssignedDriver,
        autoScheduleData,
        setAutoScheduleData,
        getDriverLists,
        driverLists,
        setDriverLists,
        handleUpdateVehicle,
        updateThisVehicle,
        setUpdateThisVehicle,
        addedVehicle,
        setAddedVehicle,
        getVehicleList,
        vehicleTripList,
        setVehicleTripList,
        tripList,
        setTripList,
        tripRecommendationData,
        setTripRecommendationData,
        selectedDateForDisplay,
        setSelectedDateForDisplay,
        isShowHeatMapMap,
        setIsShowHeatMapMap,
        tripsFixedHeight,
        setTripFixedHeight,
        bookingList,
        setBookingList,
        showHeatMap,
        setShowHeatMap,
        setSelectedStatusFilter,
        setSearchValue,
        setSelectedPaymentType,
        searchValue,
        selectedStatusFilter,
        paymentType,
        setPaymentType,
        selectedDate,
        setSelectedDate,
        selectedProgramIds,
        setSelectedProgramIds,
        heatMap,
        setHeatMap,
        selectedPaymentType,
        refreshed,
        setRefreshed,
        programLists,
        setProgramLists,
        bookingsForAutoSchedule,
        setBookingsForAutoSchedule,
        duplicateDriverAndVehicle,
        setDuplicateDriverAndVehicle,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
