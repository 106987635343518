/* eslint-disable */

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import styles from './style.module.scss';
import SchedulerComponent from '../../../../pages/fleet/new-scheduler';

import 'react-datepicker/dist/react-datepicker.css';
import {
  getAssignedVehicleTripList,
  getAutoSchedule,
  getDispatchBookingList,
  getEntityPrograms,
} from '../../../../services/dispatch';
import { generateHeatmapArrayCoordinates } from '../../../../lib/heatmap';
import HeatMapButtons from '../../../../pages/fleet/new-scheduler/heatmapButtons';
import dayjs from 'dayjs';
import Loader from '../../Loader';
import Select from '@material-ui/core/Select';
import DuetModal from '../../../Modals/modal';
import { Checkbox, Input, ListItemText, MenuItem } from '@material-ui/core';
import { Button, Form } from 'react-bootstrap';
import CalendarIcon from '../../../../assets/images/Calendar2.svg';
import './override.css';
import { useDispatchBoardContext } from '../../../../pages/fleet/drivers/DispatchBoard/MainContext';
import displayNotification from '../../../../utils/notification';

export default function TripScheduleButton() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isShowModal, setIsShowModal] = useState(false);
  const [heatMap, setHeatMap] = useState({});
  const [programLists, setProgramLists] = useState([]);
  const [showHeatMap, setShowHeatMap] = useState(false);
  const [isShowHeatMapMap, setIsShowHeatMapMap] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSelectProgram, setShowSelectProgram] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [autoScheduleData, setAutoScheduleData] = useState([]);
  const [fixedTripHeights, setFixedTripHeights] = useState(false);

  const datePicker = useRef();

  const { setTripList, tripList } = useDispatchBoardContext();

  const handleShowModal = () => {
    if (programLists.length === 0) {
      // first load of modal
      handleGetProgramEntity();
    } else {
      if (!isShowModal && programLists.length > 1) {
        setSelectedProgram([]);
        setShowSelectProgram(true);
        setIsShowModal(false);
      } else {
        setShowSelectProgram(false);
        setIsShowModal(!isShowModal);
        if (!isShowModal) {
          // reset values
          setShowHeatMap(false);
          setIsShowHeatMapMap(false);
          setSelectedDate(new Date());
        }
      }
    }
  };

  const handleGetAutoScheduleBookings = async () => {
    try {
      setIsLoading(true);
      // get booking list
      const params = {
        programs: selectedProgram?.program_id ? [selectedProgram?.program_id] : selectedProgram,
        date: dayjs(selectedDate).format('MMMM D, YYYY'),
      };
      const resp = await getDispatchBookingList(params);

      if (resp.status === 'error') {
        setIsLoading(false);
        setAutoScheduleData([]);
        return;
      }

      let bookingParams = [
        ...resp.data.bookings.filter((booking) => booking.pickup_time !== '23:59' && booking.ride_status === 0),
      ];

      bookingParams = [
        ...bookingParams.map((booking) => {
          delete booking.pickup_city; // remove this since backend doesnt handle extra params
          delete booking.dropoff_city;
          delete booking.total_addl_fee_usd_cents;
          delete booking.dropoff_signature;
          delete booking.arrival_dropoff_time;
          delete booking.driver_arrival_dropoff_time;
          const newBooking = booking;
          delete booking.broker_prebooking_id;
          return { ...newBooking };
        }),
      ];

      if (bookingParams.length === 0) {
        setIsLoading(false);
        setAutoScheduleData([]);
        return console.log('no more booking');
      }

      const copyOfBOokings = [
        ...bookingParams.map((booking) => {
          return { ...booking };
        }),
      ];

      const data = {
        date: dayjs(selectedDate).format('MMMM D, YYYY'),
        bookings: bookingParams,
        program_id: selectedProgram?.program_id ? selectedProgram?.program_id : selectedProgram[0] || null,
        config: {
          before_pickup_time: 0,
          after_pickup_time: 0,
          pickup_loading_time: 0,
          dropoff_unloading_time: 0,
        },
      };
      setIsLoading(true);
      const autoSData = await getAutoSchedule(data);
      if (autoSData.status === 'success') {
        const autData = autoSData.data?.vehicle_trip_list.map((data) => {
          data.type = 'autoScheduling';
          const newData = data.trips.map((trip) => {
            const newBookings = trip.bookings.map((booking) => {
              booking.type = 'autoScheduling';

              // get missing data , note this is just a patch for Feb 5 release
              const bookingIndex = copyOfBOokings.findIndex(
                (autoBooking) => autoBooking.booking_id === booking.booking_id
              );

              if (bookingIndex > -1) {
                booking.pickup_city = copyOfBOokings[bookingIndex].pickup_city || '';
                booking.dropoff_city = copyOfBOokings[bookingIndex].dropoff_city || '';
                booking.total_addl_fee_usd_cents = copyOfBOokings[bookingIndex].total_addl_fee_usd_cents || 0;
                booking.dropoff_signature = copyOfBOokings[bookingIndex].dropoff_signature;
                booking.arrival_dropoff_time = copyOfBOokings[bookingIndex].arrival_dropoff_time;
                booking.driver_arrival_dropoff_time = copyOfBOokings[bookingIndex].driver_arrival_dropoff_time;
              }

              return booking;
            });

            trip.bookings = [...newBookings];
            return trip;
          });

          data.trips = [...newData];

          return data;
        });

        setAutoScheduleData([...autData]);

        if (autoSData.data?.vehicle_trip_list.length > 0) {
          setTimeout(() => {
            const view = autData.length - 1;
            const element = document.getElementById(`auto-scheduling-data-${view}`);

            element?.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            });
          }, 800);
        }
      } else {
        setAutoScheduleData([]);
      }
    } catch (e) {
      console.error(e);
      setAutoScheduleData([]);
    }

    setIsLoading(false);
  };

  const handleGetProgramEntity = async () => {
    try {
      setIsLoading(true);
      const resp = await getEntityPrograms();

      if (resp.status === 'success') {
        const buf = resp.data.available_programs.filter((program) => program.manual_dispatch);
        if (buf.length === 1) {
          setSelectedProgram(buf[0]);
          setIsShowModal(true);
          setShowSelectProgram(false);
        } else {
          setShowSelectProgram(true);
        }
        buf.forEach((program) => {
          if (program?.heatmap_bounds) {
            const heatBoundsData = generateHeatmapArrayCoordinates(program?.heatmap_bounds);
            heatMap[program.program_id] = heatBoundsData;
          }
        });

        setProgramLists(buf);
        setHeatMap({ ...heatMap });
      }
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  const handleChangeProgram = (e) => {
    const selectedProgramLists = e.target.value;
    let buf = [];
    if (selectedProgramLists.includes('select-all-program')) {
      buf.push('select-all-program');
      programLists.forEach((program) => {
        buf.push(program.program_id);
      });
      setSelectedProgram(buf);
    } else {
      setSelectedProgram(e.target.value);
    }
  };

  const renderSelectedProgramLists = (selected) => {
    let selectedProgramLists = [];

    selected.forEach((item) => {
      const buf = programLists.filter((program) => program.program_id == item);

      if (buf.length) {
        selectedProgramLists.push(buf[0].program_name);
      }
    });

    if (selectedProgramLists.length === 0) {
      return <em>Select a program</em>;
    }

    return selectedProgramLists.join(', ');
  };

  const handleNext = () => {
    if (selectedProgram.length && selectedDate) {
      setShowSelectProgram(false);
      setIsShowModal(true);
    }
  };

  const handleGetTripList = async () => {
    try {
      setIsLoading(true);
      const resp = await getAssignedVehicleTripList({ date: dayjs(selectedDate).format('MMMM D, YYYY') });
      if (resp.status === 'success') {
        setTripList(resp.data.vehicle_trip_list);
      } else {
        displayNotification('Failed to load trip list for the given date.', '', 'error');
      }
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedProgram?.program_id || selectedProgram[0]) {
      handleGetAutoScheduleBookings();
    }

    if (selectedDate) {
      handleGetTripList();
    }

    return () => {
      setAutoScheduleData([]);
    };
  }, ['', selectedProgram, selectedDate]);

  return (
    <>
      <button
        onClick={handleShowModal}
        type="button"
        className={classNames('btn', 'btn-primary', styles['trip-shcedule-button'])}
        style={{
          marginLeft: '20px',
        }}
      >
        <span className={styles['trip-shcedule-text']}>Trip schedule</span>
      </button>
      {showSelectProgram && (
        <DuetModal show={showSelectProgram} close={() => setShowSelectProgram(false)}>
          <div className="form-group">
            <label className={styles.labelColor} style={{ fontSize: '18px' }}>
              Trip Schedule
            </label>
            <div style={{ fontSize: '14pt' }}>Follow the steps to trip schedule.</div>
          </div>
          <div className="form-group" style={{ display: 'relative ' }}>
            <label className={styles.labelColor}>Program</label>
            <div>
              <Select
                style={{ color: 'rgba(0, 0, 0, 0.6)', fontStyle: 'normal' }}
                labelId="demo-scheudle-mutiple-checkbox"
                id="demo-scheudle-mutiple-checkbox"
                multiple
                value={selectedProgram}
                onChange={handleChangeProgram}
                input={<Input />}
                renderValue={(selected) => renderSelectedProgramLists(selected)}
                className="normal form-control style-select"
                displayEmpty
              >
                <MenuItem value="" disabled style={{ color: 'rgba(0, 0, 0, 0.6)', fontStyle: 'normal' }}>
                  Select a program
                </MenuItem>
                {programLists.map((item) => (
                  <MenuItem key={item.program_id} value={item.program_id}>
                    <Checkbox checked={selectedProgram?.indexOf(item.program_id) > -1} />
                    <ListItemText primary={item.program_name} />
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className="form-group">
            <label className={styles.labelColor}>Day</label>
            <div>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                onSelect={(date) => setSelectedDate(date)}
                className="normal form-control dpicker"
                placeholderText="Select a date"
                autoComplete="off"
              />
            </div>
          </div>

          <div className="text-right py-lg-3 py-sm-3 py-md-3">
            <Button
              onClick={() => setShowSelectProgram(false)}
              variant="secondary"
              className={styles.sixLettersBtn}
              style={{ marginLeft: 10 }}
            >
              Cancel
            </Button>

            <Button variant="primary" className={styles.sixLettersBtn} style={{ marginLeft: 10 }} onClick={handleNext}>
              Next
            </Button>
          </div>
        </DuetModal>
      )}

      {isShowModal && (
        <div className={`${styles.modal__container} trip__schedule`}>
          <div className={styles.modal}>
            <div className={styles.modal__content}>
              {isLoading && <Loader />}
              <div className={styles.buttonContainer}>
                <div className={`row pb-2 ${styles.buttons}`}>
                  <div className="col-lg-3 col-sm-12 col-md-2 col-xl-3" />
                  <div className="col-lg-5 col-sm-12 col-md-5 col-xl-5 text-center">
                    <div className={styles.selectDate}>
                      <div>
                        <div
                          className={styles.date}
                          onClick={() => {
                            if (datePicker.current) {
                              datePicker.current.onInputClick();
                            }
                          }}
                          key={selectedDate}
                        >
                          {dayjs(selectedDate).format('dddd MMMM DD, YYYY')}&nbsp;&nbsp;&nbsp;
                          <img
                            src={CalendarIcon}
                            alt=""
                            style={{
                              height: '25px',
                              width: 'auto',
                              cursor: 'pointer',
                            }}
                          />
                        </div>
                        <DatePicker
                          ref={datePicker}
                          selected={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          onSelect={(date) => setSelectedDate(date)}
                          className={styles.datePicker}
                          placeholderText="Select a date"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-12 col-md-5 col-xl-4 text-right">
                    <div className={styles.closeButton}>
                      <div className={`heatmap-icon mr-2 ${styles.toggleClass}`}>
                        <Form.Check // prettier-ignore
                          type="radio"
                          id="fixedHeight"
                          label="Fixed Height"
                          checked={fixedTripHeights}
                          onClick={() => setFixedTripHeights(!fixedTripHeights)}
                        />
                      </div>
                      <HeatMapButtons
                        handleClick={() => setShowHeatMap(!showHeatMap)}
                        showHeatMap={showHeatMap}
                        toggleClass={styles.toggleClass}
                        handleHeatMapInfo={() => setIsShowHeatMapMap(!fixedTripHeights)}
                      />

                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light ml-3 mr-2"
                        onClick={handleShowModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.box}>
                <div className={styles.componentContainer}>
                  <SchedulerComponent
                    fixedTripHeights={fixedTripHeights}
                    isNewScheduler
                    usedForTemplate={false}
                    movedAutoScheduleData={[]}
                    clearMovedAutoScheduleData={() => {}}
                    updateAutoScheduleData={() => {}}
                    autoScheduleData={autoScheduleData}
                    isMobileView={false}
                    hideHeatMapMap={() => setIsShowHeatMapMap(false)}
                    isShowHeatMapMap={isShowHeatMapMap}
                    showHeatMap={showHeatMap}
                    selectedProgram={selectedProgram}
                    isShowDriverRouteCompare={false}
                    setShowDriverRouteCompare={() => {}}
                    tripsOfEachVehicle={[]}
                    preSelectedBooking={() => {}}
                    cancelBooking={() => {}}
                    setTripsList={() => {}}
                    refreshed={''}
                    availableTBookingTime={[]}
                    closeBookingTimeModal={() => {}}
                    updatedAddedVehicles={() => {}}
                    updateBookingList={() => {}}
                    updateBooking={() => {}}
                    searchValue={''}
                    programLists={programLists}
                    bookings={[]}
                    selectedDate={selectedDate}
                    vehicleLists={[]}
                    setSpinner={() => setIsLoading(true)}
                    unsetSpinner={() => setIsLoading(false)}
                    addedVehicle={[]}
                    updateVehicle={() => {}}
                    onClickDriverTxt={() => {}}
                    programId={null}
                    heatMapProps={heatMap}
                    tripLists={tripList}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
